import React, { Suspense, useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import './scss/style.scss';
import { AuthProvider } from './AuthContext';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


const App = () => {
  

  const [variavel, setVariavel] = useState(null);

  const handleVariavelChange = (novaVariavel) => {
    setVariavel(novaVariavel);
    console.log(novaVariavel)
  };

  return (
    <AuthProvider variavel={variavel} onVariavelChange={handleVariavelChange}>
    <HashRouter>
      <Suspense fallback={loading}>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
            <Route path="/login" element={<Login />} />
            <Route
          path="/*"
          element={
            <PrivateRoute element={<DefaultLayout />} />
          }
        />
          </Routes>
      </Suspense>
    </HashRouter>
    </AuthProvider>
  );
};

export default App;